import { Link } from 'gatsby'
import React from 'react'

const Footer = (props) => (
  <footer id="footer">
    <div className="inner">
      <ul className="copyright">
        <li>2021 &copy; Aquariosirrigation</li>
        <li>
          <Link href="/terms-of-services">Terms Of Services</Link>
        </li>
        <li>
          <Link href="/privacy-policy">Privacy Policy</Link>
        </li>
      </ul>
    </div>
  </footer>
)

export default Footer
