import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import logo from '../assets/svg/logo.svg'
const Header = props => (
  <header id="header" className="alt">
    <Link to="/" className="logo">
      <img src={logo} width="100px" />
    </Link>
    <nav>
      <a className="menu-link" onClick={props.onToggleMenu}>
        Menu
      </a>
    </nav>
  </header>
)

export default Header
