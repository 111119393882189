import React, { useState } from 'react'
import { navigate } from 'gatsby'
const initState = {
  to: '',
  name: '',
  text: '',
}
const Contact = (props) => {
  const [state, setstate] = useState(initState)
  const handleChange = (name) => (e) => {
    setstate({ ...state, [name]: e.target.value })
  }
  const sendEmail = (e) => {
    e.preventDefault()
    fetch('.netlify/functions/sendEmail', {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(state),
    })
      .then(() => {
        navigate('/')
        setstate(initState)
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }
  return (
    <section id="contact">
      <div className="inner">
        <section>
          <form method="post" onSubmit={sendEmail}>
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input
                value={state.name}
                onChange={handleChange('name')}
                type="text"
                name="name"
                id="name"
              />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input
                value={state.to}
                onChange={handleChange('to')}
                type="text"
                name="email"
                id="email"
              />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea
                name="message"
                id="message"
                rows="6"
                value={state.text}
                onChange={handleChange('text')}
              ></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Clear" />
              </li>
            </ul>
          </form>
        </section>
        <section className="split">
          <section>
            <div className="contact-method">
              <span className="icon alt fa-envelope"></span>
              <h3>Email</h3>
              <a href="mailto:contact@aquariosirrigation.com">
                contact@aquariosirrigation.com
              </a>
            </div>
          </section>
          <section>
            <div className="contact-method">
              <span className="icon alt fa-phone"></span>
              <h3>Phone</h3>
              <span>Pedro y Juan Navarro</span>
              <br />
              <span>(718) 683-7101</span>
            </div>
          </section>
          <section>
            <div className="contact-method">
              <span className="icon alt fa-home"></span>
              <h3>Address</h3>
              <span>
                5720 84st
                <br />
                Flushing ,NY 11373
                <br />
                United States of America
              </span>
            </div>
          </section>
        </section>
      </div>
    </section>
  )
}

export default Contact
